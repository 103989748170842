import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~8],
		"/about": [~10],
		"/commission": [~11,[2]],
		"/portfolio": [~12,[3]],
		"/portfolio/[portfolioPage]": [~13,[3]],
		"/shop": [~14,[4],[5]],
		"/shop/collections": [~15,[4],[5]],
		"/shop/collections/[handle]": [~16,[4],[5,6]],
		"/shop/products": [~17,[4],[5]],
		"/shop/products/[handle]": [~18,[4],[5,7]],
		"/[...path]": [~9]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';